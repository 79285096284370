import React from 'react'
import { Route } from "react-router-dom"

import _ from 'lodash'

import gql from "graphql-tag"
import { Query } from "react-apollo"

import Templates from './Templates'
import EditTemplate from './EditTemplate'
import NewTemplate from './NewTemplate'

const LANDING_PAGE_TEMPLATES = gql`
  query LandingPageTemplates($filterBy: LandingPageFilterInput, $sortBy: LandingPageSortBy, $cursor: LandingPageTemplatesCursorInput) {
    landingPageTemplates(filterBy: $filterBy, sortBy: $sortBy, cursor: $cursor) {
      templates {
        id
        name
        premium
        position
        live
        screenshotUrl
        tags
        pages {
          id
          position
          unlayerId
          screenshotUrl
        }
      }
      cursor {
        hasNextPage
      }
    }
  }
`;

export default class LandingPageTemplates extends React.Component {

  default = {
    filterBy: {
      live: true
    },
    sortBy: 'POSITION',
    count: 0,
    cursor: {
      offset: 0,
      limit: 30
    }
  }

  state = { ...this.default }

  callback = (index, { name, value }, reset = false, reposition = false) => {
    const { filterBy, count } = this.state
    if(reset) {
      if(reposition) {
        delete this.default.filterBy.live
        this.default.cursor = {}
      }
      else {
        this.default.filterBy.live = true
        this.default.cursor = {
          offset: 0,
          limit: 30
        }
      }
      this.setState({...this.default, count: count+1 })
    }
    else {
      if(index === 'filterBy') {
        if ( value !== '' )
          this.setState({ filterBy: { ...filterBy, [name]: value }})
        else
        {
          let newFilterBy = { ...filterBy }
          delete newFilterBy[name]
          this.setState({ filterBy: newFilterBy })
        }
      }
      if(index === 'sortBy') {
        this.setState({ sortBy: value })
      }
    }
  }

  hasFilters = () => {
    const { filterBy, sortBy } = this.state
    const { ...stateFilterBy } = filterBy
    const { ...defaultFilterBy } = this.default.filterBy
    const isEqual = _.isEqual(stateFilterBy,defaultFilterBy)

    if(!isEqual || this.default.sortBy !== sortBy )
    {
      return true
    }
    else
      return false
  }

  render() {
    const { match, partner } = this.props
    const { filterBy: filters, sortBy: sorts } = this.state

    return (
      <Query query={LANDING_PAGE_TEMPLATES} variables={this.state} fetchPolicy='network-only'>
        {({ loading, error, data, refetch, fetchMore }) => {
          if (!data && loading) return "Loading Templates..."
          if (error) return `Error! ${error.message}`

          return (
            <div>
              <Route 
                exact 
                path={`${match.path}`} 
                render={(props) => <Templates {...props} partner={partner} 
                templates={data.landingPageTemplates.templates}
                fetchMore={fetchMore}
                cursor={data.landingPageTemplates.cursor}
                refetchTemplates={refetch} 
                fetchTemplatesLoading={loading}
                sorts={sorts} 
                filters={filters} 
                callback={this.callback} 
                hasFilters={this.hasFilters()} />} 
              />
              <Route path={`${match.path}/new`} render={(props) => <NewTemplate {...props} templates={data.landingPageTemplates} refetchTemplates={refetch} />} />
              <Route path={`${match.path}/:id/edit`} render={(props) => <EditTemplate {...props} refetchTemplates={refetch} />} />
            </div>
          )
        }}
      </Query>
    )
  }
}
