import React, { useEffect, useState } from 'react'
import { InputLabel, Input, FormControl, Button, FormControlLabel, Checkbox, Dialog } from '@material-ui/core'
import CreatableSelect from 'react-select/creatable'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { TagOptions } from '../../common/constants'
import { CREATE_JOURNEY_TEMPLATE, UPDATE_JOURNEY_TEMPLATE } from '../../../graphql/mutation/journey'
import { FETCH_JOURNEY_TEMPLATE } from '../../../graphql/query/journey'

const getInputField = (props) => {
  const { type, isMulti } = props || {}

  switch(type) {
    case 'checkbox':
      return  <FormControlLabel
        control={
          <Checkbox
            checked={props?.value}
            {...props}
            onChange={(e) => props?.onChange(e.target.checked)}
          />
        }
        label={props?.checkboxLabel}
      />
    case 'text':
      return <Input {...props} onChange={(e) => props?.onChange(e.target.value)} />
    case 'number':
        return <Input {...props} onChange={(e) => props?.onChange(e.target.value)}/>
    case 'select':
    const inputSelectProps = {
      ...props,
      value: isMulti ? props?.options?.filter(item => props?.value?.includes(item.value)) : props?.options?.find(item => item.value === props?.value),
      onChange: (value) => isMulti ? props?.onChange(Array.isArray(value) ? value?.map(item => item?.value) : [value]?.map(item => item?.value)) : props?.onChange(value?.value)
    }
    return <>
      <CreatableSelect {...inputSelectProps} className="mt-12"
        styles={{
          control: styles => ({ ...styles, 
            margin: 0,
            border: 0,
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            borderRadius: 0,
            minHeight: 32,
            boxShadow: 'none',
            ':hover': {
              borderColor: 'rgba(0, 0, 0, 0.42)',
            },
          }),
          container: styles => ({
            fontFamily: 'sans-serif',
            // zIndex: 2,
          }),
          indicatorsContainer: styles => ({
            display: 'none',
          }),
          placeholder: styles => ({
            display: 'none',
          }),
        }}
      />
    </>
  }
}

const EditTemplate = (props) => {
  const { history, refetch, partner = {} } = props
  const id = useParams()?.id
  const [state, setState] = useState({ name: '', description: '', position: 0, live: false, siteIds: [] })
  const [showEditor,setShowEditor] = useState(false)
  const [createJourneyTemplate] = useMutation(CREATE_JOURNEY_TEMPLATE)
  const [updateJourneyTemplate] = useMutation(UPDATE_JOURNEY_TEMPLATE)
  const { data: { journeyTemplate } = {}} = useQuery(FETCH_JOURNEY_TEMPLATE, { variables: { id: parseInt(id) }})

  const listenForMessage = (event) => {
    if (event?.data?.action === 'journeyTemplate#close') {
      setShowEditor(false)
    }
  }

  useEffect(() => {
    const { id, ...attr } = journeyTemplate || {}
    journeyTemplate && setState(attr)
  },[journeyTemplate])

  useEffect(() => {
    window.addEventListener('message', listenForMessage)
    return () => window.removeEventListener('message',listenForMessage)
  },[])

  const inputs = [
    { name: 'name', type: 'text', label: 'Name' },
    { name: 'description', type: 'text', label: 'Description' },
    { name: 'position', type: 'number', label: 'Position' },
    { name: 'tags', type: 'select', label: 'Tags', options: TagOptions('emails'), className: 'mt-0', isMulti: true},
    { name: 'siteIds', type: 'select', label: 'Enter SiteIds', options: state.siteIds?.map(site => ({label: site, value: site})), className: 'mt-0', isMulti: true},
    { name: 'live', type: 'checkbox', checkboxLabel: 'Live' },
  ]

  const handleSiteIds = (value) => {
    const siteIds = !value.includes(undefined) ? value.map(item => {
      return item
    }) : []
    setState({ ...state, siteIds })
  }

  const iframeUrl = partner?.parameterizedName === 'appflows' ? process.env.REACT_APP_APPFLOWS : process.env.REACT_APP_NETLIFY

  return (
    <div className="flex items-center justify-center mt-12">
      <form className="flex flex-col max-w-lg w-full" onSubmit={async(e) => {
        e.preventDefault()
        let resp = null
        const { name, position, tags, live, description, siteIds } = state
        if(id) {
          await updateJourneyTemplate({
            variables: {
              input: {
                id: parseInt(id),
                name,
                description,
                tags,
                live,
                position: parseInt(position),
                siteIds
              }
            }
          }).then(({ data }) => {
            const { updateJourneyTemplate } = data || {}
            const { success } = updateJourneyTemplate || {}
            success && history.push('/journey_templates')
          })
        }
        else {
          await createJourneyTemplate({
            variables: {
              input: { 
                name,
                description, 
                tags,
                live,
                position: parseInt(position),
                siteIds
              }
            }
          }).then(({ data }) => {
            const { createJourneyTemplate } = data || {}
            const { success } = createJourneyTemplate || {}
            success && history.push('/journey_templates')
          })
        }
      }}>
        {
          inputs.map((item) => {
            const itemProps = {
              ...item,
              value: item.name === 'siteIds' ? Array.isArray(state.siteIds) ? state?.[item.name] : [] : state?.[item.name],
              onChange: value => (item.name === 'siteIds' && Array.isArray(value)) ? handleSiteIds(value) : setState({ ...state, [item.name]: value }),
            }
            return (
              <FormControl margin="normal" fullWidth>
                { item?.label && <InputLabel>{item?.label}</InputLabel> }
                { getInputField(itemProps) }
              </FormControl>
            )
          })
        }
        <div className='mt-2'>
          {
            id && 
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => { setShowEditor(true) }}
            >
              Edit Journey
            </Button>
          }
        </div>
        <div className='mt-2'>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </div>
      </form>
      <Dialog
        fullScreen
        open={showEditor}
        onClose={() => { setShowEditor(false) }}
      >
        <iframe className="inset-0 w-full h-full" src={`${iframeUrl}/journey-template/${id}?user_token=${localStorage.getItem('mailmunch:token')}`}></iframe>
      </Dialog>
    </div>
  )
}

export default EditTemplate