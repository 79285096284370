import gql from "graphql-tag"

const UPDATE_PARTNER_PROFILE = gql`
  mutation updatePartnerProfile(
    $id: Int!
    $name: String
    $platformFeatures: JSON
    $logo: JSON
    $appDomain: String
    $formsDomain: String
    $pagesDomain: String
    $cdnDomain: String
    $labels: JSON
    $emailSettings: JSON
    $links: JSON
  ) {
    updatePartnerProfile(input: {
      id: $id
      name: $name
      platformFeatures: $platformFeatures
      logo: $logo
      appDomain: $appDomain
      formsDomain: $formsDomain
      pagesDomain: $pagesDomain
      cdnDomain: $cdnDomain
      labels: $labels
      emailSettings: $emailSettings
      links: $links
    }) {
      success
      error
    }
  }
`

export default UPDATE_PARTNER_PROFILE
