import React from 'react'
import { Route } from "react-router-dom"
import _ from 'lodash'
import gql from "graphql-tag"
import { Query } from "react-apollo"

import Templates from './Templates'
import EditTemplate from './EditTemplate'
import DuplicateTemplate from './DuplicateTemplate'
import NewTemplate from './NewTemplate'

const FORM_TEMPLATES = gql`
  query Theme($filterBy: ThemeFilterInput,$sortBy: ThemeSortBy, $cursor: ThemesCursorInput) {
    themes(filterBy: $filterBy, sortBy: $sortBy, cursor: $cursor) {
      templates {
        id
        name
        premium
        position
        live
        partnerId
        closeButton
        designFormat
        defaultProperties
        screenshotUrl
        widgetTypeId
        steps {
          id
          name
          position
          screenshotUrl
        }
        tags
      }
      cursor {
        hasNextPage
      }
    }
  }
`;

const WIDGET_TYPES = gql`
  {
    widgetTypes {
      id
      name
      wordpress
      __typename
    }
  }
`;

const FormTemplates = (props) => {

  return (
    <Query query={WIDGET_TYPES}>
      {({ loading, error, data: { widgetTypes } = {}, }) => {
        if (loading) return "Loading Widget types..."
        if (error) return `Error! ${error.message}`
        
        return (
          <EmailTemplates widgetTypes={widgetTypes} {...props}/>
        )
      }}
    </Query>
  )
}

class EmailTemplates extends React.Component {

  default = {
    filterBy: {
      ...this.props.widgetTypes.length > 0 ? { widgetTypeId: this.props.widgetTypes[0].id } : {},
      live: true
    },
    sortBy: 'POSITION',
    count: 0,
    cursor: {
      offset: 0,
      limit: 30
    }
  }

  state = { ...this.default }

  callback = (index, { name, value }, reset = false, reposition = false) => {
    const { filterBy, count } = this.state
    if(reset) {
      if(reposition) {
        this.default.filterBy.widgetTypeId = filterBy.widgetTypeId
        delete this.default.filterBy.live
        this.default.cursor = {}
      }
      else {
        this.default.filterBy.widgetTypeId = filterBy.widgetTypeId
        this.default.filterBy.live = true
        this.default.cursor = {
          offset: 0,
          limit: 30
        }
      }
      this.setState({...this.default, count: count + 1 })
    }
    else {
      if(index === 'filterBy') {
        if ( value !== '' )
          this.setState({ filterBy: { ...filterBy, [name]: value }})
        else
        {
          let newFilterBy = { ...filterBy }
          delete newFilterBy[name]
          this.setState({ filterBy: newFilterBy })
        }
      }
      if(index === 'sortBy') {
        this.setState({ sortBy: value })
      }
    }
  }

  hasFilters = () => {
    const { filterBy, sortBy } = this.state
    const { widgetTypeId, ...stateFilterBy } = filterBy
    const { widgetTypeId: defaultWidgetTypeId, ...defaultFilterBy } = this.default.filterBy
    const isEqual = _.isEqual(stateFilterBy,defaultFilterBy)

    if(!isEqual || this.default.sortBy !== sortBy )
    {
      return true
    }
    else
      return false
  }

  render() {
    const { match, partner, widgetTypes } = this.props
    const { filterBy: filters, sortBy: sorts } = this.state

    return (
      <Query query={FORM_TEMPLATES} variables={this.state} fetchPolicy='network-only'>
        {({ loading, error, data, refetch, fetchMore, ...props }) => {
          if (!data && loading) return "Loading Templates..."
          if (error) return `Error! ${error.message}`
          return (
            <div>
              <Route 
                exact 
                path={`${match.path}`} 
                render={(props) => <Templates {...props} 
                partner={partner} 
                widgetTypes={widgetTypes} 
                sorts={sorts} 
                filters={filters} 
                templates={data.themes.templates}
                fetchTemplatesLoading={loading}
                cursor={data.themes.cursor}
                fetchMore={fetchMore}
                refetchTemplates={refetch} 
                callback={this.callback} 
                hasFilters={this.hasFilters()}/>}
              />
              <Route path={`${match.path}/new`} render={(props) => <NewTemplate {...props} isNew={true} widgetTypes={widgetTypes} templates={data.themes} refetchTemplates={refetch} />} />
              <Route path={`${match.path}/:id/duplicate`} render={(props) => <DuplicateTemplate {...props} isNew={true} widgetTypes={widgetTypes} templates={data.themes} refetchTemplates={refetch} />} />
              <Route path={`${match.path}/:id/edit`} render={(props) => <EditTemplate {...props} widgetTypes={widgetTypes} templates={data.themes} refetchTemplates={refetch} />} />
            </div>
          )
        }}
      </Query>
    )
  }
}

export default FormTemplates