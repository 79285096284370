import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';

import featuresList from './data/featuresList';
import { marketingLabels, marketingLabelsPlaceholders } from './data/marketingLabels';
import logos from './data/logos';
import emailSettings from './data/emailSettings';

import FeatureSwitch from './FeatureSwitch';
import Domains from './Domains';
import MarketingLabels from './MarketingLabels';
import Logos from './Logos';
import EmailSettings from './EmailSettings';

import UPDATE_PARTNER_PROFILE from '../../graphql/mutation/updatePartnerProfile';
import FETCH_PARTNER from '../../graphql/query/fetchPartner';

import { groupMarketingLabels, groupLogos, sortSmtpSettings } from '../../utils';

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: 30,
  },
  heading: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(1),
    marginTop: 10,
  },
  container: {
    ...theme.container,
    display: 'flex',
    justifyContent: 'center',
    padding: '50px 0px',
  },
  block: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: '150%',
    width: '100%',
    zIndex: 990,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    boxShadow: '0 0 5px 10px rgba(255, 255, 255, 0.5)',
    display: 'none',
    justifyContent: 'center',
  },
  loader: {
    marginTop: 300,
  },
  tabContainer: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: 1000,
  },
  tab: {
    '& > * > * > *': {
      justifyContent: 'space-around',
    }
  },
  logosContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  domainContainer: {
    columnCount: 2,
    columnGap: 155,
    width: '100%'
  },
  emailSettingsContainer: {
    columnCount: 2,
    columnGap: 155,
    width: '100%'
  },
  button: {
    float: 'right',
  },
  progress: {
    display: 'flex',
    '& > div': {
      color: 'white',
    },
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    marginRight: 10,
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: 'white',
  boxShadow: '24px 24px 24px 0px rgba(0,0,0,0.75)',
  padding: '20px',
};

const TabPanel = ({ children, value, index, ...other }) => {

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

const Settings = ({ partner, ...props }) => {
  const classes = useStyles();
  const [tabState, setTabState] = useState(0);
  const [open, setOpen] = useState(false);
  const [newLink, setNewLink] = useState({
    name: '',
    value: '',
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [snackBarState, setSnackBarState] = useState({
      snackBarOpen: false,
      vertical: 'top',
      horizontal: 'center',
  });
  const [errors, setErrors] = useState({
    domain: {
      cdnDomain : false,
      appDomain: false,
      pagesDomain: false,
      formsDomain: false,
    },
    links: {},
    emailSettings: {
      port: false,
      address: false,
    }
  });

  const TextFields = [
  {
    name: 'name',
    label: 'Name',
    placeholder: 'Name of the link',
    value: newLink.name,
    onChange: (event)=> setNewLink({...newLink, name: event.target.value})
  },
  {
    name: 'link',
    label: 'Link',
    placeholder: 'Enter the link',
    value: newLink.value,
    onChange: (event)=> {
      const { name, value } = event.target;
      setNewLink({...newLink, value})
      let newErrors = {
      ...errors,
      'links': {
        ...errors.links,
        [name]: false
        }
      }
      setErrors(newErrors);
    }
  }
  ];

  const [updatePartnerProfile, { loading: mutationLoading, error: mutationError }] = useMutation(UPDATE_PARTNER_PROFILE, {
    onCompleted: data => {
      setSnackBarState({ ...snackBarState, snackBarOpen: true });
      document.getElementById('blockDiv').style.display = 'flex'; 
      setTimeout(() => {
        const { history } = props;
        history.push('/');
      }, 3000);
    },
    onError: data => {
      setSnackBarState({ ...snackBarState, snackBarOpen: true });
    }
  });
  const [state, setState] = useState({});
  const { loading, error } = useQuery(FETCH_PARTNER, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const { currentPartner = {} } = data;
      setState({
        labels: !!Object.keys(currentPartner.labels).length ? currentPartner.labels : marketingLabels,
        logo: currentPartner.logo || logos,
        platformFeatures: !!Object.keys(currentPartner.platformFeatures).length ? currentPartner.platformFeatures : featuresList,
        cdnDomain: currentPartner.cdnDomain || '',
        appDomain: currentPartner.appDomain || '',
        pagesDomain: currentPartner.pagesDomain || '',
        formsDomain: currentPartner.formsDomain || '',
        emailSettings: !!Object.keys(currentPartner.emailSettings).length ? currentPartner.emailSettings : emailSettings,
        links: currentPartner.links || {},
      })
    },
  });
  const setStateWrapper = (key, value) => {
    let newState = Object.assign({}, state)
    newState[key] = value
    setState(newState)
  }

  const changeTabState = (event, newValue) => {
    setTabState(newValue);
  };

  const handleLabelsChange = event => {
    const { name, value } = event.target
    const newLabels = {
      ...state.labels,
      [name]: value
    }
    setStateWrapper('labels', newLabels)
  }
  
  const handleDomainChange = event => {
    const { name, value } = event.target
    let newErrors = {
      ...errors,
      'domain': {
        ...errors.domain,
        [name]: false
      }
    }
    setErrors(newErrors)
    setStateWrapper(name, value)
  }
  
  const handleLinkChange = event => {
    const { name, value } = event.target
    let newErrors = {
      ...errors,
      'links': {
        ...errors.links,
        [name]: false
      }
    }
    const newLink = {
      ...state.links,
      [name]: value
    }
    setErrors(newErrors)
    setStateWrapper('links', newLink)
  }

  // const handleDeleteLink = (event, link) => {
  //   console.log(link, 'link');
  //   let newLinks = {
  //     ...state.links,
  //   }
  //   delete newLinks[link]
  //   setStateWrapper('links', newLinks)
  //   handleSave(event)
  // }

  const handleEmailSettingsChange = event => {
    const { name, value, checked } = event.target
    let newErrors = {
      ...errors,
      'emailSettings': {
        ...errors.emailSettings,
        [name]: false
      }
    }
    const newEmailSettings = {
      ...state.emailSettings,
      smtpSettings: {
        ...state.emailSettings.smtpSettings,
        [name]: name === 'enableStarttlsAuto' ? checked : value
      },
    }
    setErrors(newErrors)
    setStateWrapper('emailSettings', newEmailSettings)
  }

  const handleDomainBlur = event => {
    const { name, value } = event.target
    const expression = /https?:\/\/(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?/;
    const urlPattern = new RegExp(expression);
    if(!urlPattern.test(value)){
      let newErrors = {
        ...errors,
        ...(['cdnDomain', 'appDomain', 'pagesDomain', 'formsDomain'].includes(name) ? {
        'domain': {
          ...errors.domain,
          [name]: true
        }
      } : {
        'links': {
          ...errors.links,
          [name]: true
        }
      })
      }
      setErrors(newErrors)
    } 
  }
  
  const handleEmailSettingsBlur = event => {
    event.preventDefault()
    const { name, value } = event.target
    if(name === 'address') {
      const expression = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/;
      const urlPattern = new RegExp(expression);
      if(!urlPattern.test(value)){
        let newErrors = {
          ...errors,
          'emailSettings': {
            ...errors.emailSettings,
            [name]: true
          }
        }
        setErrors(newErrors)
      } 
    }
  }
  
  const handleLogosChange = (name, value) => {
    const newLogo = {
      ...state.logo,
      [name]: {
        url: value,
      }
    }
    setStateWrapper('logo', newLogo)
  }
  
  const handleFeatureChange = label => event => {
    event.cancelBubble = true;
    event.stopPropagation();
    const newFeatures = {
      ...state.platformFeatures,
      [label]: {
        ...state.platformFeatures[label],
        enabled: event.target.checked,
      }
    }
    setStateWrapper('platformFeatures', newFeatures)
  }
  
  const handleDetailsChange = (label, feature) => event => {
    const newFeatures = {
      ...state.platformFeatures,
      [label]: {
        ...state.platformFeatures[label],
        features: {
          ...state.platformFeatures[label].features,
          [feature]: { enabled: event.target.checked }
        }
      }
    }
    setStateWrapper('platformFeatures', newFeatures)
  }

  const  submitNewLink = (event) => {
    state.links[newLink.name?.toLowerCase()] = newLink.value
    handleSave(event);
    handleClose();
  }

  const handleSave = event => {
    event.preventDefault();
    const { id } = partner;
    updatePartnerProfile({
      variables: { id, ...state },
    });
  }
  
  const handleCancel = event => {
    event.preventDefault();
    const { history } = props;
    history.push('/');
  }
  
  const handleSnackBarClose = event => {
    event.preventDefault();
    setSnackBarState({ ...snackBarState, snackBarOpen: false });
  };
  
  if (loading || !Object.keys(state).length) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  
  return (
    <Container className={classes.container}>
      <div id="blockDiv" className={classes.block}>
        <CircularProgress className={classes.loader} size={80} />
      </div>
      <form noValidate autoComplete="off">
        <Typography className={classes.title} variant="h4" color="inherit" >
          <Snackbar
            anchorOrigin={{ vertical: snackBarState.vertical, horizontal: snackBarState.horizontal }}
            key={`${snackBarState.vertical},${snackBarState.horizontal}`}
            open={snackBarState.snackBarOpen}
            onClose={mutationError ? handleSnackBarClose : undefined}
          >
            <MuiAlert elevation={6} variant="filled" onClose={mutationError ? handleSnackBarClose : undefined} severity={(mutationError ? "error" : "success")}>
              {!mutationError ? "Changes saved successfully!" : "Changes could not be saved. Please try again."}
            </MuiAlert>
          </Snackbar>
          Partner Account Settings
          <Button
            disabled={Object.values({...errors.domain, ...errors.emailSettings, ...errors.links}).includes(true)}
            className={classes.button}
            size="large"
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            { mutationLoading && ( 
            <div className={classes.progress}>
              <CircularProgress size={14} />
            </div> )} Save
          </Button>
          <Button
            style={{"marginRight": 50}}
            className={classes.button}
            size="large"
            variant="contained"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Typography>
        <div className={classes.tabContainer}>
          <AppBar position="static" className={classes.tab}>
            <Tabs value={tabState} onChange={changeTabState} aria-label="simple tabs example">
              <Tab label="NAME AND MARKETING LABEL" />
              <Tab label="LOGOS" />
              <Tab label="FEATURES" />
              <Tab label="ADVANCED CONFIGURATIONS" />
              <Tab label="EMAIL SETTINGS" />
            </Tabs>
          </AppBar>
          <TabPanel value={tabState} index={0}>
          <div className={classes.heading}>Name and Marketing Label</div>
            {
              Object.keys(groupMarketingLabels(state.labels)).map((label, index) => {
                return (
                  <MarketingLabels
                    key={`labels-${index}`}
                    label={label}
                    placeholders={marketingLabelsPlaceholders(partner.name)}
                    values={groupMarketingLabels(state.labels)[label]}
                    handleChange={handleLabelsChange}
                  />
                )
              })
            }
          </TabPanel>
          <TabPanel value={tabState} index={1}>
            <div className={classes.heading}>Logos</div>
            <div className={classes.logosContainer}>
            {
              groupLogos(state.logo).map((logosGroup, index) => {
                return (
                  <Logos
                    key={`logogroup-${index}`}
                    logosGroup={logosGroup}
                    partner={partner}
                    handleImageChange={handleLogosChange}
                  />
                )
              })
            }
            </div>
          </TabPanel>
          <TabPanel value={tabState} index={2}>
            <div className={classes.heading}>Features</div>
            {
              Object.keys(state.platformFeatures).map((key, index) => {
                return (
                  <FeatureSwitch 
                    key={`features-${index}`}
                    label={key}
                    featureDetails={state.platformFeatures[key]}
                    handleFeatureChange={handleFeatureChange}
                    handleDetailsChange={handleDetailsChange}
                  />
                )
              })
            }
          </TabPanel>
          <TabPanel value={tabState} index={3}>
            <div className={classes.heading}>Advanced Configurations</div>
            <div className={classes.domainContainer}>
              {
                Object.keys({'Cdn': state.cdnDomain, 'App': state.appDomain, 'Pages': state.pagesDomain, 'Forms': state.formsDomain}).
                map((key, index) => {
                  return (
                    <Domains
                      key={`features-${index}`}
                      label={key === 'Cdn' ? key.toUpperCase() : key + ' Domain'}
                      value={state[key.toLowerCase()+'Domain']}
                      name={key.toLowerCase()+'Domain'}
                      error={errors.domain[key.toLowerCase()+'Domain']}
                      handleBlur={handleDomainBlur}
                      handleChange={handleDomainChange}
                    />
                  )
                })
              }
            </div>
            {/* Add New Link Feature */}
            {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px', alignItems: 'center' }}>
            <div className={classes.heading} style={{ marginTop: 0, paddingTop: 0 }}>Links Configurations</div>
            <Button
            style={{"marginRight": 50}}
            className={classes.button}
            size="large"
            color="primary"
            variant="contained"
            onClick={handleOpen}
          >
            Add New Link
          </Button>
          <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
          <Box style={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Enter Link Details
          </Typography>
          {
            TextFields?.map((field, index) => {
              return (
                <TextField
                key={`textfield-${index}`}
                label={field.label}
                name={field.name}
                value={field.value}
                placeholder={field.placeholder}
                onChange={field.onChange}
                error={errors.links[field.name]}
                onBlur={ (e) => field.name === 'link' && handleDomainBlur(e)}
                helperText={errors.links[field.name] && 'Please enter a valid URL'}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                fullWidth
                />
              )
            })
          }
          <Button
            disabled={Object.values({...errors.links}).includes(true) || TextFields.some(field => field.value === '')}
            className={classes.button}
            size="small"
            variant="contained"
            color="primary"
            onClick={(event)=> submitNewLink(event)}
          >
            { mutationLoading && ( 
            <div className={classes.progress}>
              <CircularProgress size={14} />
            </div> )} Save
          </Button>
          <Button
            className={classes.button}
            style={{"marginRight": 50}}
            size="small"
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
        </Modal>
        </div> */}
            <div className={classes.domainContainer}>
              {
                Object.entries(state?.links).
                map((item, index) => {
                  return (
                    <Domains
                      key={`feature-${index}`}
                      label={item?.[0]?.charAt(0).toUpperCase() + item?.[0]?.slice(1)}
                      value={item?.[1]}
                      name={item?.[0].toLowerCase()}
                      error={errors.links[item?.[0].toLowerCase()]}
                      handleBlur={handleDomainBlur}
                      handleChange={handleLinkChange}
                      // icon={'Delete'}
                      // handleIconClick={(e) => handleDeleteLink(e, item?.[0])}
                    />
                  )
                })
              }
            </div>
          </TabPanel>
          <TabPanel value={tabState} index={4}>
            <div className={classes.heading}>Email Settings</div>
            <div className={classes.emailSettingsContainer}>
            {
              Object.keys(sortSmtpSettings(state.emailSettings.smtpSettings)).
              map((key, index) => {
                return (
                  <EmailSettings
                    key={`emailsettings-${index}`}
                    name={key}
                    type={key === 'password' ? 'password' : 'text'}
                    value={state.emailSettings.smtpSettings[key]}
                    error={errors.emailSettings[key]}
                    handleBlur={handleEmailSettingsBlur}
                    handleChange={handleEmailSettingsChange}
                  />
                )
              })
            }
            </div>
          </TabPanel>
        </div>
      </form>
    </Container>
  );
};

export default Settings;
