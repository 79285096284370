import React, { useState, useCallback, useEffect, useReducer } from 'react'

import update from 'immutability-helper'
import { post } from 'axios'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import gql from "graphql-tag"
import { useMutation } from '@apollo/react-hooks';
import { Mutation } from "react-apollo"
import PixieImageEditor from '../../common/pixie'
import TagsModal from '../../common/TagsModal'
import { savePixieFileAndUpload } from '../../../utils/pixie';

import { actions, filterBy, sortBy } from './FilterConfig'

import UPDATE_LANDING_PAGE_TEMPLATE from '../../../graphql/query/updateLandingPageTemplate'
import BULK_ASSIGN_TAGS_TO_LANDING_PAGE_TEMPLATES from '../../../graphql/mutation/bulkAssignTagsToLandingPageTemplates';
import BULK_REMOVE_TAGS_FROM_LANDING_PAGE_TEMPLATES from '../../../graphql/mutation/bulkRemoveTagsFromLandingPageTemplates';

import TemplateCard from '../../common/DraggableCard'
import FilterBar from '../../common/filterBar'
import RepositionBar from '../../common/repositionBar'

const DELETE_LANDING_PAGE_TEMPLATE = gql`
  mutation DeleteLandingPageTemplate($id: Int!) {
    deleteLandingPageTemplate(input: { id: $id }) {
      success
    }
  }
`;

const LandingPageTemplates = (props) => {
  const { refetchTemplates, partner, callback, hasFilters, sorts, filters, cursor, fetchMore, fetchTemplatesLoading } = props;
  const { tags: { landingPages: landingPagesTags} } = partner 
  const [progress, setProgress] = useState({})
  const [selectedCards, setSelectedCards] = useState([]);
  const [state,setState] = useState({
    isReposition: false,
    maxWidth: 350,
    templates: props.templates,
    cursor: cursor
  })
  const [loading, setLoading] = useState(false)
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false)
  const { isReposition, templates } = state
  const [updateLandingPageTemplate] = useMutation(UPDATE_LANDING_PAGE_TEMPLATE);
  const [bulkAssignTagsToLandingPageTemplates] = useMutation(BULK_ASSIGN_TAGS_TO_LANDING_PAGE_TEMPLATES);
  const [bulkRemoveTagsFromLandingPageTemplates] = useMutation(BULK_REMOVE_TAGS_FROM_LANDING_PAGE_TEMPLATES);

  useEffect(() => {
    setState({ ...state, templates: props.templates, cursor })
  },[props.templates])

  const resetFilter = (reposition = false) => {
    callback(null, {}, true, reposition)
  }

  const updateState = (values, doUpdate = false, doRefetch = false) => {
    if(values.isReposition !== undefined)
    values.isReposition === true ? resetFilter(values.isReposition) : resetFilter()
    setState({ ...state, ...values, ...(doRefetch && { templates: props.templates })}) 
    if(doUpdate) {
      setLoading(true)
      Promise.all(
        templates.map(async ({id, position},index) => {
          // TODO update theme positions
          await updateLandingPageTemplate({ variables: {
            id,
            position: index
          }})
      })).then(() => {
        refetchTemplates().then(() => setLoading(false))
      })
    }
  }

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = templates[dragIndex]
      setState({
        ...state,
        templates:
        update(templates, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      })
    },
    [state],
  );
  
  const resetTemplateImage = (id) => {
    updateLandingPageTemplate({
      variables: {
        id,
        screenshotUrl: null
      }
    })
  }
  
  const onSave = (file, name) => {
    const templateId = window.templateId
    const config = {
      onUploadProgress: progressEvent => {
        setProgress({ [templateId]: ((progressEvent.loaded/progressEvent.total) * 100) })
      }
    }
    
    savePixieFileAndUpload(file, name, partner, config)
      .then(response=>{
        const {url, key} = response;
        updateLandingPageTemplate({
          variables: {
            id: templateId,
            screenshotUrl: `${url}/${key}`
          }
        }).then((resp) => {
          refetchTemplates().then(() => {
            setProgress({ [templateId]: null })
          })
        })
      }).catch((err)=> {
        console.error(err)
      });
    
      
    window.pixie.close();
    delete window.pixie;
  }
  
  
  /**
   * event: { target: { value: { id, tags }, checked } }
   */
  const onCardSelect = useCallback(
    (event) => {
      const { value, checked } = event.target;
      const parsedValue = JSON.parse(value);
      if(checked) {
        selectedCards.push(parsedValue)
      } else {
        const selectedCardIndex = selectedCards.map(selectedCard => selectedCard.id).indexOf(parsedValue.id)
        selectedCards.splice(selectedCardIndex, 1)
      }
      
      setSelectedCards(selectedCards)
    },
  )
  
  const onBulkTagsAssign = (currentTags) => {
    const data = {
      tags:currentTags.map(currentTag => currentTag.value),
      templatePageIds: selectedCards.map(selectedCard => parseInt(selectedCard.id))
    }
    
    bulkAssignTagsToLandingPageTemplates({
      variables: data
    }).then((resp) => {
      refetchTemplates()
    })
  }
  
  const onBulkTagsRemove = (currentTags) => {
    const data = {
      tags:currentTags.map(currentTag => currentTag.value),
      templatePageIds: selectedCards.map(selectedCard => parseInt(selectedCard.id))
    }
    
    bulkRemoveTagsFromLandingPageTemplates({
      variables: data
    }).then((resp) => {
      refetchTemplates()
    })
  }

  window.onscroll = () => {
    const { scrollHeight, scrollTop, clientHeight } = document.documentElement
    if((Math.floor(scrollHeight - scrollTop) <= clientHeight) && state.cursor.hasNextPage){
      setFetchMoreLoading(true)
      fetchMore({
        variables: {
          cursor: {
            offset: state.templates.length,
            limit: 30
          }
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setFetchMoreLoading(false)
          setState({
            ...state,
            templates: [...templates, ...fetchMoreResult.landingPageTemplates.templates],
            cursor: { ...cursor, ...fetchMoreResult.landingPageTemplates.cursor }
          })
          return {
            landingPageTemplates: {
              templates: [...templates, ...fetchMoreResult.landingPageTemplates.templates],
              cursor: { ...cursor, ...fetchMoreResult.landingPageTemplates.cursor },
              __typw: "LandingPageTemplatesOutput"
            }
          } 
        }
      })
    }
  }

  return (
    <div style={{ position: 'relative'}}>
      <PixieImageEditor
        key="landing-pages-pixie"
        id="landing-pages"
        onSave={(file, name)=> onSave(file, name)}
      />
      
      <FilterBar 
        config={{ filterBy, actions, sortBy }} 
        callback={callback} 
        disabled={isReposition}
        sorts={sorts}
        filters={filters}
        tagKey='landingPages' 
      />
      
      <TagsModal 
        tags={landingPagesTags}
        selectedCards={selectedCards}
        onSave={onBulkTagsAssign}
        onBulkTagsRemove={onBulkTagsRemove}
      />
      
      <Grid container direction="row" spacing={2} style={{ padding: 24 }}>
        {
        loading || fetchTemplatesLoading ? <CircularProgress color="secondary" /> :
        <>

        <RepositionBar {...state} callback={updateState} resetFilter={resetFilter} hasFilters={hasFilters}/>
        <Mutation mutation={DELETE_LANDING_PAGE_TEMPLATE}>
        {(deleteLandingPageTemplate, { error, loading, data }) => {
          if (loading) return <div>Loading...</div>
          if (error) return <div>There was an error.</div>
          if (data && data.deleteLandingPageTemplate && data.deleteLandingPageTemplate.success) {
            refetchTemplates()
          }

          return templates.map((template,index) => {
            return (
              <TemplateCard 
                key={`landing-page-${template.id}`}
                updateFunc={UPDATE_LANDING_PAGE_TEMPLATE}
                to={'landing_pages'}
                index={index}
                id={template.id}
                moveCard={moveCard}
                deleteFunc={deleteLandingPageTemplate} 
                template={template} 
                isReposition={isReposition}
                maxWidth={state.maxWidth}
                progress={progress[template.id]}
                resetThemeImage={resetTemplateImage}
                refetchTemplates={refetchTemplates}
                usePixie={true}
                onCardSelect={onCardSelect}
              />
            )
          })
        }}
        </Mutation>
        </>
        }
      </Grid>
      {
        !!fetchMoreLoading &&
        <Grid container alignItems="center" justify="center" style={{ paddingTop: "5px" }}>
          Loading Data...
        </Grid>
      }
    </div>
  )
}

export default LandingPageTemplates
