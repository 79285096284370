
import gql from 'graphql-tag'
import { JourneyTemplate} from '../fragments'

const CREATE_JOURNEY_TEMPLATE = gql`
  mutation CreateJourneyTemplate($input: CreateJourneyTemplateInput!) {
    createJourneyTemplate(input: $input) {
      success
      journeyTemplate {
        ${JourneyTemplate}
      }
    }
  }
`

const DELETE_JOURNEY_TEMPLATE = gql`
  mutation DeleteJourneyTemplate($input: DeleteJourneyTemplateInput!) {
    deleteJourneyTemplate(input: $input) {
      success
      journeyTemplate {
        ${JourneyTemplate}
      }
    }
  }
`;

const UPDATE_JOURNEY_TEMPLATE = gql`
  mutation UpdateJourneyTemplate($input: UpdateJourneyTemplateInput!) {
    updateJourneyTemplate(input: $input) {
      success
      journeyTemplate {
        ${JourneyTemplate}
      }
    }
  }
`

export { CREATE_JOURNEY_TEMPLATE, DELETE_JOURNEY_TEMPLATE, UPDATE_JOURNEY_TEMPLATE }