import { useState } from "react";
import gql from "graphql-tag"
import { Query, Mutation } from "react-apollo"
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { copyToClipboard } from "../../utils";

const FETCH_USERS = gql`
  query partnerUsers {
    partnerUsers {
      id
      email
      foreignUserId
    }
  }
`;

const GENERATE_SSO_LINK = gql`
  mutation GenerateSSOLink($id: String!) {
    generateSSOLink(id: $id) {
      success
      error
      sso
    }
  }
`;

const Impersonate = (props) => {
  const [open, setOpen] = useState(false)
  const [SSOLink, setSSOLink] = useState(null)
  const [snackbarStatus, setSnackbarStatus] = useState(false)
  return (
    <Query query={FETCH_USERS} fetchPolicy='cache-first'>
      {({ loading, error, data }) => {
        if (!data && loading) return "Loading Templates..."
        if (error) return `Error! ${error.message}`
        const { partnerUsers = {} } = data || {}
        return (
          <div className="overflow-x-auto p-8 relative">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                          <th scope="col" className="py-3 px-6">
                              User Id
                          </th>
                          <th scope="col" className="py-3 px-6">
                              Email Address
                          </th>
                          <th scope="col" className="py-3 px-6">
                              Generate SSO Link
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                    {partnerUsers?.map(user => {
                      return <><tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white ">
                            <span className='bg-gray-300 text-gray-600 rounded px-1 py-1'>{user?.id}</span>
                        </th>
                        <td className="py-4 px-6">
                            {user?.email}
                        </td>
                        <td className="py-4 px-6">
                          <Mutation mutation={GENERATE_SSO_LINK}>
                            {(generateSSOLink, { loading}) => {
                              const userId = (props.partner && props.partner.useForeignIds) ? user?.foreignUserId : user.id.toString()
                              return (
                                <Button
                                  variant="contained" 
                                  color="secondary"
                                  className="w-44 h-9"
                                  onClick={async () => {
                                    const { data } = await generateSSOLink({ variables: { id: userId } })
                                    const { sso, success } = data.generateSSOLink || {} 
                                    const { url, expires_at } = sso || {}
                                    if(success && url) {
                                      setSSOLink(url)
                                      setOpen(true)
                                    }
                                  }}
                                    >
                                      {loading ? <CircularProgress size={16} color="#fff"  /> :  'generate sso link' }
                                </Button>
                              )
                            }}
                          </Mutation>
                        </td>
                      </tr>
                    </>    
              })}
                  </tbody>
              </table>
              <Dialog onClose={() => setOpen(false)} open={open}>
                <DialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
                  Log In Link
                </DialogTitle>
                <DialogContent dividers style={{width: '500px', height: '100px'}}>
                  <Typography gutterBottom>Please make sure you open this link in an incognito window.</Typography>
                  <Typography gutterBottom>This link is only valid for 60 seconds.</Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained" 
                    color="secondary"
                    onClick={() => {
                        copyToClipboard(SSOLink)
                        setSnackbarStatus(true)
                    }}
                      >
                      copy sso link
                  </Button>
                  <Button autoFocus color="secondary" onClick={() => setOpen(false)}>Close</Button>
                </DialogActions>
              </Dialog>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarStatus}
                autoHideDuration={2000}
                onClose={() => setSnackbarStatus(false)}
                >
                  <Alert onClose={() => setSnackbarStatus(false)} severity="success" sx={{ width: '100%' }}> Copied to clipboard successfully!</Alert>
                </Snackbar>
        
          </div>
        )
      }}
    </Query>
  )
}

export default Impersonate