import React from 'react'

import gql from "graphql-tag"
import { Query, Mutation } from "react-apollo"

import Editor from './Editor'

import FETCH_THEMES_COUNT from '../../../graphql/query/fetchThemesCount'

const CREATE_FORM_TEMPLATE = gql`
  mutation createTheme($name: String!, $position: Int, $live: Boolean, $premium: Boolean, $designFormat: String!, $closeButton: JSON, $defaultProperties: JSON, $steps: [CreateThemeStepInput], $widgetTypeId: Int, $tags: JSON, $siteIds: JSON) {
    createTheme(input: { name: $name, position: $position, live: $live, premium: $premium, designFormat: $designFormat, closeButton: $closeButton, defaultProperties: $defaultProperties, steps: $steps, widgetTypeId: $widgetTypeId, tags: $tags, siteIds: $siteIds }) {
      success
      theme {
        id
        name
        premium
        position
        live
        designFormat
        closeButton
        defaultProperties
        widgetTypeId
        siteIds
        steps {
          id,
          name,
          position,
        }
      }
    }
  }
`;

export default class NewTemplate extends React.Component {
  render() {
    const { refetchTemplates, widgetTypes, history } = this.props
    return (
      <Query query={FETCH_THEMES_COUNT} fetchPolicy='network-only'>
        {({ loading, error, data , refetch, ...props }) => {
          if (!data && loading) return "Loading Template..."
          if (error) return `Error! ${error.message}`
          const { totalThemesCount: themesCount } = data
          
          return (
            <Mutation mutation={CREATE_FORM_TEMPLATE}>
            {(createTheme, { error, loading, data }) => {
              //if (loading) return <div>Loading...</div>
              if (error) return <div>There was an error.</div>
              // if (data && data.createTheme && data.createTheme.success) {
              //   refetchTemplates().then(() => {
              //     history.push('/form_templates')
              //   })
              //   return <div>Loading...</div>
              // }

              return (
                <Editor createNew={true} themesCount={themesCount} widgetTypes={widgetTypes} isNew={true} onSave={(data, doRedirect=true) => {
                  createTheme({
                    variables: data,
                    refetchQueries: [
                      'getCurrentUser'
                    ]
                  }).then(({data}) => {
                    if (data && data.createTheme && data.createTheme.success) {
                      const { id } = data.createTheme.theme
                      if(doRedirect) {
                        history.push('/form_templates')
                      }
                      else
                        history.push(`/form_templates/${id}/edit`)
                    }
                  })
                }} />
              )
            }}
            </Mutation>
          )
        }}
      </Query>
    )
  }
}
