import React from 'react'

import uuidv4 from 'uuid/v4'

import UnlayerEditor from '../UnlayerEditor'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Card from './Card'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CreatableSelect from 'react-select/creatable';
import update from 'immutability-helper'
import _ from 'lodash'

import { UserContext } from '../../../UserContext'
import { getTagOptions } from '../../common/constants';
import CodeMirrorEditor from '../../common/CodeMirrorEditor';
import { copyToClipboard } from '../../../utils'
import Switch from '@material-ui/core/Switch';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  cardContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  flex: {
    flex: 1,
  },
  main: {
    width: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 100
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  reactSelect: {
    zIndex: 1
  }
})

function Transition(props) {
  return <Slide direction="up" {...props} />
}

class Container extends React.Component {
  constructor(props) {
    super(props)
    
    const { template = {}, templatesCount } = this.props
    this.state = {
      name: template.name || '',
      position: template.position || templatesCount + 1,
      premium: template.premium || false,
      live: template.live || false,
      pages: template.pages && template.pages.sort((a, b) => {
        return a.position - b.position
      }) || [],
      templatePagePosition: null,
      showEditor: false,
      editorOpen: false,
      tags: template.tags || [],
      selectedPageId: null,
      showCodeMirror: false,
      showAlert: false,
      alertData: {
        type: 'success', message: null
      },
      showOnAllSite: !(template.siteIds && template.siteIds.length > 0) ,
      siteIds: Array.isArray(template.siteIds) ? template.siteIds : []
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if(this.props.template) {
      const { pages } = this.props.template
      // update state when step are refetched
      if(!_.isEqual(prevProps.template.pages,pages)) {
        this.setState({ pages: pages && pages.sort((a, b) => {
          return a.position - b.position
        }) || []})
      }
    }
  }

  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
    })
  }

  handleChange = (newValue, actionMeta) => {
    const tags = newValue ? newValue.map(item => {
      return item.value
    }) : []
    this.setState({ tags })
  };

  handleSiteIds = (newValue, actionMeta) => {
    const siteIds = newValue ? newValue.map(item => {
      return item.value
    }) : []
    this.setState({ siteIds })
  };

  handleChangeToggle = (e) => {
    this.setState({
      [e.target.name]:!this.state[e.target.name]
    })
  }

  handleDuplicate = (pageIndex) => {
    const { pages } = this.state
    const { json, html, css, screenshotUrl } = pages[pageIndex]
    this.addPage({ json, html, screenshotUrl, css })
  }

  handleDelete = (pageIndex) => {
    const { pages } = this.state
    const newPages = update(pages, {
      $splice: [[pageIndex, 1]],
      $apply: function(pages) {
        return pages.map((page,index) => {
          return update(page, {
            position: { $set: index } 
          })
        })
      }
    });

    this.setState({
      pages: newPages
    })
  }
  
  moveCard = (dragIndex, hoverIndex) => {
    const { pages } = this.state
    const dragCard = pages[dragIndex]
    this.setState({
      ...this.state,
      pages:
      update(pages, {
        $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]],
        $apply: function(pages) {
          return pages.map((page,index) => {
            return update(page, {
              position: { $set: index } 
            })
          })
        }
      }),
    })
  }

  render() {
    const { classes, onSave } = this.props
    const { name, position, premium, live, pages, templatePagePosition, tags, alertData, showAlert, stringifyedJson, showCodeMirror, showOnAllSite, siteIds } = this.state
    let user = this.context
    const options = getTagOptions(user,'landingPages')
    
    return (
      <div className={classes.main}>
        <form onSubmit={async(e) => {
          e.preventDefault()
          onSave({ name, position: parseInt(position), premium, live, pages, tags, siteIds: showOnAllSite ? [] : siteIds })
        }}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="name">Name</InputLabel>
            <Input
              id="name"
              name="name"
              value={this.state.name}
              onChange={(e) => { this.handleInputChange(e) }}
              autoFocus
            />
          </FormControl>
          
          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="position">Position</InputLabel>
            <Input
              id="position"
              name="position"
              value={this.state.position}
              onChange={(e) => { this.handleInputChange(e) }}
            />
          </FormControl>

          <FormControl margin="normal" fullWidth classes={{ root: classes.reactSelect }}>
            <InputLabel className="MuiInputLabel-shrink" htmlFor="tags">Tags</InputLabel>
            <CreatableSelect
              isMulti
              onChange={this.handleChange}
              options={options}
              defaultValue={tags.map(item => ({ value: item, label: item }))}
              styles={{ 
                control: styles => ({ ...styles, 
                  margin: 0,
                  marginTop: 16,
                  border: 0,
                  borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                  borderRadius: 0,
                  minHeight: 32,
                  boxShadow: 'none',
                  ':hover': {
                    borderColor: 'rgba(0, 0, 0, 0.42)',
                  },
                }),
                container: styles => ({
                  fontFamily: 'sans-serif',
                  zIndex: 2,
                }),
                indicatorsContainer: styles => ({
                  display: 'none',
                }),
                placeholder: styles => ({
                  display: 'none',
                }),
              }}
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              value="start"
              control={<Switch color="primary" name="showOnAllSite" checked={showOnAllSite} defaultChecked={true} onChange={ (e) => { this.handleChangeToggle(e) } } />}
              label="Show on all sites"
              labelPlacement="start"
              style={{ marginLeft: '0' }}
            />
          </FormControl>
          {
            !showOnAllSite && 
            <FormControl style={{ marginTop: '0' }} required fullWidth>
              <InputLabel htmlFor="siteIds">Site Ids</InputLabel>
              <CreatableSelect
                isMulti
                onChange={this.handleSiteIds}
                defaultValue={siteIds.map(item => ({ value: item, label: item }))}
                formatCreateLabel={userInput => `Add siteId "${userInput}"`}
                styles={{ 
                  control: styles => ({ ...styles, 
                    margin: 0,
                    marginTop: 16,
                    border: 0,
                    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                    borderRadius: 0,
                    minHeight: 32,
                    boxShadow: 'none',
                    ':hover': {
                      borderColor: 'rgba(0, 0, 0, 0.42)',
                    },
                  }),
                  container: styles => ({
                    fontFamily: 'sans-serif'
                  }),
                  indicatorsContainer: styles => ({
                    display: 'none',
                  }),
                  placeholder: styles => ({
                    display: 'none',
                  }),
                }}
              />
            <FormHelperText>Enter Site Ids</FormHelperText>
            </FormControl>
          }
          <FormControl margin="normal" fullWidth>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.premium}
                    onChange={(e) => { this.handleInputChange(e) }}
                    name="premium"
                    value="premium"
                  />
                }
                label="Premium"
              />
            
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.live}
                    onChange={(e) => { this.handleInputChange(e) }}
                    name="live"
                    value="live"
                  />
                }
                label="Live"
              />
            </FormGroup>
          </FormControl>

        <FormGroup row>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => { this.addPage() }}
          >
            Add Page
          </Button>
        </FormGroup>
        
        <FormGroup row>
          <Grid container direction="row" spacing={2} className={classes.cardContainer}>
          {pages.map((page,index) => {
            const funcProps = {
              handleEditorOpen: () => this.handleEditorOpen(page),
              card: { name: `Page ${page.position}`, ...page, index },
              handleDelete: () => this.handleDelete(index),
              handleDuplicate: () => this.handleDuplicate(index),
            }
            const stringifyedJson = JSON.stringify(page.json, null, 2);

            return (
              <Card 
                onMenuItemSelect={(value) => {
                  if (value === 'copy') {
                    copyToClipboard(stringifyedJson).then(() => {
                      this.setState({
                        showAlert: true,
                        alertData: {
                          type: 'success',
                          message: 'JSON Copied to Clipboard !'
                        }
                      });
                    }).catch(() => {
                      this.setState({
                        showAlert: true,
                        alertData: {
                          type: 'error',
                          message: 'Error in copying to clipboard !'
                        }
                      });
                    });
                  } else {
                    this.setState({ 
                      showCodeMirror: true, 
                      stringifyedJson, 
                      selectedPageId: page.id 
                    });
                  }
                }}
                key={`page-${page.id}`} 
                {...funcProps} 
                moveCard={this.moveCard}
              />
              // <Grid key={`page-${page.position}`} item xs={6} sm={3}>
              //   <Card>
              //     <CardContent>
              //       <Typography color="textSecondary" gutterBottom>
              //         Page {page.position}
              //       </Typography>
              //     </CardContent>
              //     <CardActions>
              //       <Button color="primary" size="small" onClick={() => { this.handleEditorOpen(page) }}>Edit</Button>
              //     </CardActions>
              //   </Card>
              // </Grid>
            )
          })}
          </Grid>
        </FormGroup>
        
          <hr />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Save
          </Button>
        </form>
        
        <Dialog
          fullScreen
          open={this.state.showEditor}
          onClose={this.handleEditorClose}
          TransitionComponent={Transition}
        >
          <AppBar color="secondary" className={classes.appBar}>
            <Toolbar>
              <IconButton color="inherit" onClick={this.handleEditorClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                Editor
              </Typography>
              <Button color="inherit" onClick={this.handleEditorSave}>
                save
              </Button>
            </Toolbar>
          </AppBar>
          
          {templatePagePosition != null && <UnlayerEditor
            displayMode="web"
            json={pages[templatePagePosition].json}
            templateId={pages[templatePagePosition].unlayerId}
            closeEditor={this.handleEditorClose}
          />}
        </Dialog>

        <CodeMirrorEditor
          onEditorChanged={(editor, data, value) => this.setState({ stringifyedJson: value })}
          onEditorCancel={() => this.setState({ showCodeMirror: false, selectedPageId: null })}
          onSave={() => this.handleJsonSave({ onSave })}
          stringifyedJson={stringifyedJson}
          isOpen={showCodeMirror} 
          transition={Transition}
          classes={classes}
        />

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showAlert}
          autoHideDuration={5000}
          onClose={() => this.setState({ showAlert: false })}
        >
          <Alert severity={alertData.type}>
            {alertData.message}
          </Alert>
        </Snackbar>
      </div>
    )
  }

  handleJsonSave = ({ onSave }) => {
    try {
      const { stringifyedJson, selectedPageId, pages, name, position, premium, live, tags } = this.state;
      const filteredPages = [];
      let updatedPage = {};

      if (['null', '{}'].includes(stringifyedJson)) {
        throw 'JSON cannot be empty';
      }
      const parsedJson = JSON.parse(stringifyedJson);
      
      pages.forEach((page) => {
        if (page.id !== selectedPageId) {
          filteredPages.push(page);
        } else if (page.id === selectedPageId) {
          updatedPage = page
        }
      });
      onSave({
        name, 
        position: parseInt(position), 
        premium, 
        live, 
        tags,
        pages: [
          ...filteredPages, 
          { ...updatedPage, json: parsedJson }
        ] 
      }, false);
      this.setState({ 
        showCodeMirror: false, 
        showAlert: true,
        alertData: {
          type: 'success', message: 'JSON updated successfully !'
        }
      });
    } catch (error) {
      this.setState({
        showAlert: true,
        alertData: {
          type: 'error', message: 'Invalid JSON'
        }
      });
    }
  };
  
  handleEditorSave = () => {
    const { pages, templatePagePosition } = this.state
    window.unlayer.exportHtml(result => {
      let json = result.design
      let html = result.chunks.body
      let css = result.chunks.css
      let fonts = result.chunks.fonts
      
      let newPages = [...pages]
      newPages[templatePagePosition].json = json
      newPages[templatePagePosition].html = html
      newPages[templatePagePosition].css = css
      newPages[templatePagePosition].fonts = fonts
      delete newPages[templatePagePosition].unlayerId
      this.setState({ pages: newPages }, () => {
        const { onSave } = this.props
        const { name, position, premium, live, pages, tags } = this.state
        onSave({ name, position: parseInt(position), premium, live, pages, tags },false)
      })
      this.handleEditorClose()
    })
  }

  handleEditorClose = () => {
    this.setState({
      showEditor: false,
      templatePagePosition: null
    })
  }

  handleEditorOpen = (page) => {
    this.setState({
      showEditor: true,
      templatePagePosition: page.position
    })
  }

  addPage({ json, html, css, screenshotUrl } = {}) {
    const { template } = this.props
    let newPage = {
      position: this.state.pages.length,
      json: json || null,
      html,
      css,
      screenshotUrl,
    }
    if (template && template.id) newPage['id'] = uuidv4()
    this.setState({
      pages: [
        ...this.state.pages,
        newPage
      ]
    })
  }
}

Container.contextType = UserContext
export default withStyles(styles)(Container)
