export const autoAddFields = [{
  name: 'email',
  label: 'Email',
  type: 'email',
  options: [],
  meta_data: {},
  placeholder_text: 'Enter email here',
  required: true
}];

export const defaultFields = [{
  name: 'email',
  label: 'Email',
  type: 'email',
  options: [],
  meta_data: {},
  placeholder_text: 'Enter email here',
  required: true
}, {
  name: 'name',
  label: 'Name',
  type: 'text',
  options: [],
  meta_data: {},
  placeholder_text: 'Enter name'
}, {
  name: 'first_name',
  label: 'First Name',
  type: 'text',
  options: [],
  meta_data: {},
  placeholder_text: 'Enter first name'
}, {
  name: 'last_name',
  label: 'Last Name',
  type: 'text',
  options: [],
  meta_data: {},
  placeholder_text: 'Enter last name'
}, {
  name: 'phone',
  label: 'Phone',
  type: 'text',
  options: [],
  meta_data: {},
  placeholder_text: 'Enter phone number'
}, {
  name: 'website',
  label: 'Website',
  type: 'text',
  options: [],
  meta_data: {},
  placeholder_text: 'Enter website'
}, {
  name: 'company',
  label: 'Company',
  type: 'text',
  options: [],
  meta_data: {},
  placeholder_text: 'Enter company'
}, {
  name: 'zip_code',
  label: 'Zip Code',
  type: 'text',
  options: [],
  meta_data: {},
  placeholder_text: 'Enter zip code'
}, {
  name: 'subscriber_consent',
  type: 'checkbox',
  label: 'Subscriber Consent',
  options: 'I agree to terms of service and privacy policy',
  show_label: false,
  required: true,
  meta_data: {
    consent: true
  }
}];
