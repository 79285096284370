import React from 'react'
import { Route } from "react-router-dom"

import Templates from './Templates'
import EditTemplate from './EditTemplate'

const JourneyTemplates = (props) => {
  const { match, partner } = props
  
  return (
    <div>
      <Route exact path={`${match.path}`} render={(props) => <Templates {...props} partner={partner} />}  />
      <Route path={`${match.path}/new`} render={(props) => <EditTemplate {...props} partner={partner} />} />
      <Route path={`${match.path}/:id/edit`} render={(props) => <EditTemplate {...props} partner={partner} /> } />
    </div>
  )
}

export default JourneyTemplates