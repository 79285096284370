import React from 'react'

import { Mutation, Query } from "react-apollo"

import Editor from './Editor'
import UPDATE_THEME from '../../../graphql/query/updateTheme'
import FETCH_THEME from '../../../graphql/query/fetchTheme'

export default class EditTemplate extends React.Component {
  render() {
    const { match: { params }, templates, refetchTemplates, widgetTypes, history } = this.props
    const templateId = parseInt(params.id)
    //const template = templates.find((t) => t.id === templateId)

    return (
      <Query query={FETCH_THEME} variables={{ id: templateId }} fetchPolicy='network-only'>
        {({ loading, error, data , refetch, ...props }) => {
          if (!data && loading) return "Loading Template..."
          if (error) return `Error! ${error.message}`
          const { theme: template } = data
          
          return (
            <Mutation mutation={UPDATE_THEME}>
            {(updateTheme, { error, loading, data }) => {
              //if (loading) return <div>Loading...</div>
              if (error) return <div>There was an error.</div>
              // if (data && data.updateTheme && data.updateTheme.success) {
              //   return <div>Loading...</div>
              // }

              return (
                <div>
                  <Editor template={template} widgetTypes={widgetTypes} onSave={(data,doRedirect=true) => {
                    const steps = data.steps.map((item) => {
                      const cpyItem = {...item}
                      delete cpyItem.__typename
                      delete cpyItem.screenshotUrl 
                      return cpyItem
                    })
                    updateTheme({
                      variables: {
                        id: templateId,
                        name: data.name,
                        position: data.position,
                        premium: data.premium,
                        live: data.live,
                        steps: steps,
                        closeButton: data.closeButton,
                        defaultProperties: data.defaultProperties,
                        tags: data.tags,
                        siteIds: data.siteIds
                      },
                      refetchQueries: [
                        'getCurrentUser'
                      ]
                    }).then(({data}) => {
                      if (data && data.updateTheme && data.updateTheme.success) {
                        refetch()
                        doRedirect && history.push('/form_templates')
                        // doRedirect && refetchTemplates().then(() => {
                        //   history.push('/form_templates')
                        // })
                      }
                    })
                  }} />
                </div>
              )
            }}
            </Mutation>
          )
        }}
        </Query>
    )
  }
}
