import gql from 'graphql-tag'
import { JourneyTemplate } from '../fragments';

const FETCH_JOURNEY_TEMPLATES = gql`
  query JourneyTemplates($filterBy: JourneyTemplateFilterInput, $sortBy: JourneyTemplateSortByInput) {
    journeyTemplates(filterBy: $filterBy, sortBy: $sortBy) {
      ${JourneyTemplate}
    }
  }
`;

const FETCH_JOURNEY_TEMPLATE = gql`
  query JourneyTemplate($id: Int!) {
    journeyTemplate(id: $id) {
      ${JourneyTemplate}
    }
  }
`;

export { FETCH_JOURNEY_TEMPLATES, FETCH_JOURNEY_TEMPLATE }