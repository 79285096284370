import React from 'react'
import { Redirect } from "react-router-dom"

import gql from "graphql-tag"
import { Mutation, Query } from "react-apollo"
import FETCH_EMAIL_TEMPLATE from '../../../graphql/query/fetchEmailTemplate'
import Editor from './Editor'
import SnackBar from "../../common/SnackBar"

const UPDATE_EMAIL_TEMPLATE = gql`
  mutation UpdateEmailTemplate($id: Int!, $name: String, $position: Int, $live: Boolean, $premium: Boolean, $json: JSON, $html: String, $unrollTemplateId: Int, $designFormat: String, $tags: JSON, $slug: String, $webflowSettings: JSON, $siteIds: JSON) {
    updateEmailTemplate(input: { id: $id, name: $name, position: $position, live: $live, premium: $premium, json: $json, html: $html, unrollTemplateId: $unrollTemplateId, designFormat: $designFormat, tags: $tags, slug: $slug, webflowSettings: $webflowSettings, siteIds: $siteIds }) {
      success
      error
      emailTemplate {
        id
        name
        premium
        position
        live
        json
        tags
        unrollTemplateId
        designFormat
        slug
        webflowSettings
        siteIds
      }
    }
  }
`
export default class EditTemplate extends React.Component {

  state = {
    updateError: false,
    error: ""
  }

  render() {
    const { match: { params }, templates, partner } = this.props
    const templateId = parseInt(params.id)
    //const template = templates.find((t) => t.id === templateId)

    return (
      <>
        <SnackBar state={this.state.updateError} error={this.state.error} handleClose={() => this.setState({updateError: false})}/>
        <Query query={FETCH_EMAIL_TEMPLATE} variables={{ id: templateId }} fetchPolicy='network-only'>
          {({ loading, error, data , refetch, ...props }) => {
            if (!data && loading) return "Loading Template..."
            if (error) return `Error! ${error.message}`
            const { emailTemplate: template } = data
            
            return (
              <Mutation mutation={UPDATE_EMAIL_TEMPLATE}>
              {(updateEmailTemplate, { error, loading, data }) => {
                if (loading) return <div>Loading...</div>
                if (error) return <div>There was an error.</div>
                if (data && data.updateEmailTemplate && data.updateEmailTemplate.success) {
                  return <Redirect to="/email_templates" />
                }

                return (
                  <div>
                    <Editor template={template} partner={partner} onSave={(data) => {
                      updateEmailTemplate({
                        variables: {
                          id: templateId,
                          html: data.html,
                          name: data.name,
                          position: data.position,
                          premium: data.premium,
                          live: data.live,
                          json: data.json,
                          tags: data.tags,
                          slug: data.slug,
                          webflowSettings: data.webflowSettings,
                          siteIds: data.siteIds
                        },
                        refetchQueries: [
                          'getCurrentUser'
                        ]
                      }).then(resp => {
                        if(!resp.data.updateEmailTemplate.success){
                          this.setState({
                            error: resp.data.updateEmailTemplate.error,
                            updateError: true
                          })
                        }
                      })
                    }} />
                  </div>
                )
              }}
              </Mutation>
            )
          }}
          </Query>
        </>
      )
  }
}
