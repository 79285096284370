import React, { useState, useCallback, useEffect } from 'react'
import gql from "graphql-tag"
import { Mutation } from "react-apollo"
import { useMutation } from '@apollo/react-hooks';
import update from 'immutability-helper'
import { Grid, CircularProgress } from '@material-ui/core'

import RepositionBar from '../../common/repositionBar'
import FilterBar from '../../common/filterBar'
import TemplateCard from '../../common/DraggableCard'
import PixieImageEditor from '../../common/pixie';
import UPDATE_EMAIL_TEMPLATE from '../../../graphql/query/updateEmailTemplate'
import BULK_ASSIGN_TAGS_TO_EMAIL_TEMPLATES from '../../../graphql/mutation/bulkAssignTagsToEmailsTemplates'
import BULK_REMOVE_TAGS_FROM_EMAIL_TEMPLATES from '../../../graphql/mutation/bulkRemoveTagsFromEmailsTemplates'

import { actions, filterBy, sortBy } from './FilterConfig'
import { savePixieFileAndUpload } from '../../../utils/pixie';

import TagsModal from '../../common/TagsModal'

const DELETE_EMAIL_TEMPLATE = gql`
  mutation DeleteEmailTemplate($id: Int!) {
    deleteEmailTemplate(input: { id: $id }) {
      success
    }
  }
`;

const EmailTemplates = (props) => {
  const { refetchTemplates, partner, callback, hasFilters, sorts, filters, cursor, fetchMore, fetchTemplatesLoading } = props
  const { tags: { emails: emailTags} } = partner 
  const [progress, setProgress] = useState({})
  const [state,setState] = useState({
    isReposition: false,
    maxWidth: 350,
    templates: props.templates,
    cursor: cursor
  })
  const [loading, setLoading] = useState(false)
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false)
  const { isReposition, templates } = state
  const [updateEmailTemplate] = useMutation(UPDATE_EMAIL_TEMPLATE);
  const [bulkAssignTagsToEmailTemplates] = useMutation(BULK_ASSIGN_TAGS_TO_EMAIL_TEMPLATES);
  const [bulkRemoveTagsFromEmailTemplates] = useMutation(BULK_REMOVE_TAGS_FROM_EMAIL_TEMPLATES);
  const [selectedCards, setSelectedCards] = useState([]);
  
  useEffect(() => {
    setState({ ...state, templates: props.templates, cursor });
    
    if(selectedCards.length > 0) {
      const updatedSelectedCards = props.templates.filter(template => selectedCards.map(card => card.id).includes(template.id))
      .map(template => ({id: template.id, tags: template.tags}));
      setSelectedCards(updatedSelectedCards);
    }
  },[props.templates]);

  const resetFilter = (reposition = false) => {
    callback(null, {}, true, reposition)
  }

  const updateState = (values, doUpdate = false, doRefetch = false) => {
    if(values.isReposition !== undefined)
    values.isReposition === true ? resetFilter(values.isReposition) : resetFilter()
    setState({ ...state, ...values, ...(doRefetch && { templates: props.templates })}) 
    if(doUpdate) {
      setLoading(true)
      Promise.all(
        templates.map(async ({id, position},index) => {
          // TODO update theme positions
          await updateEmailTemplate({ variables: {
            id,
            position: index
          }})
      })).then(() => {
        refetchTemplates().then(() => setLoading(false))
      })
    }
  }

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = templates[dragIndex]
      setState({
        ...state,
        templates:
        update(templates, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      })
    },
    [state],
  )
  
  const resetTemplateImage = (id) => {
    updateEmailTemplate({
      variables: {
        id,
        screenshotUrl: null
      }
    })
  }
  
  const onSave = (file, name) => {
    const templateId = window.templateId;
    const config = {
      onUploadProgress: progressEvent => {
        setProgress({ [templateId]: ((progressEvent.loaded/progressEvent.total) * 100) })
      }
    }
    
    savePixieFileAndUpload(file, name, partner, config)
      .then(response=>{
        const {url, key} = response;
        updateEmailTemplate({
          variables: {
            id: templateId,
            screenshotUrl: `${url}/${key}`
          }
        }).then(() => {
          refetchTemplates().then(() => {
            setProgress({ [templateId]: null })
          })
        })
      }).catch((err)=> {
        console.error(err)
      });
      
    window.pixie.close(); 
  }
  
  /**
   * event: { target: { value: { id, tags }, checked } }
   */
  const onCardSelect = useCallback(
    (event) => {
      const { value, checked } = event.target;
      const parsedValue = JSON.parse(value);
      if(checked) {
        selectedCards.push(parsedValue)
      } else {
        const selectedCardIndex = selectedCards.map(selectedCard => selectedCard.id).indexOf(parsedValue.id)
        selectedCards.splice(selectedCardIndex, 1)
      }
      
      setSelectedCards(selectedCards)
    },
  )
  
  const onBulkTagsAssign = (currentTags) => {
    const data = {
      tags:currentTags.map(currentTag => currentTag.value),
      emailTemplateIds: selectedCards.map(selectedCard => parseInt(selectedCard.id))
    }
    
    bulkAssignTagsToEmailTemplates({
      variables: data
    }).then((resp) => {
      refetchTemplates()
    })
  }

  const onBulkTagsRemove = (currentTags) => {
    const data = {
      tags:currentTags.map(currentTag => currentTag.value),
      emailTemplateIds: selectedCards.map(selectedCard => parseInt(selectedCard.id))
    }
    
    bulkRemoveTagsFromEmailTemplates({
      variables: data
    }).then((resp) => {
      refetchTemplates()
    })
  }

  window.onscroll = () => {
    const { scrollHeight, scrollTop, clientHeight } = document.documentElement
    if((Math.floor(scrollHeight - scrollTop) <= clientHeight) && state.cursor.hasNextPage){
      setFetchMoreLoading(true)
      fetchMore({
        variables: {
          cursor: {
            offset: state.templates.length,
            limit: 30
          }
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setFetchMoreLoading(false)
          setState({
            ...state,
            templates: [...templates, ...fetchMoreResult.emailTemplates.templates],
            cursor: { ...cursor, ...fetchMoreResult.emailTemplates.cursor }
          })
          return {
            emailTemplates: {
              templates: [...templates, ...fetchMoreResult.emailTemplates.templates],
              cursor: { ...cursor, ...fetchMoreResult.emailTemplates.cursor },
              __type: "EmailTemplatesOutput"
            }
          } 
        }
      })
    }
  }

  return (
    <div style={{ position: 'relative'}}>
      <PixieImageEditor
        key="email-pixie"
        id="email"
        onSave={(file, name) => onSave(file, name)}
      />
      <FilterBar 
        config={{ filterBy, actions, sortBy }} 
        callback={callback} 
        disabled={isReposition}
        sorts={sorts} filters={filters}
        tagKey='emails'
      />
      
      <TagsModal 
        tags={emailTags}
        selectedCards={selectedCards}
        onSave={onBulkTagsAssign}
        onBulkTagsRemove={onBulkTagsRemove}
      />
      
      <Grid container direction="row" spacing={2} style={{ padding: 8*3 }}>
        {
          loading || fetchTemplatesLoading ? <CircularProgress color="secondary" /> :
          <>
            <RepositionBar {...state} callback={updateState} resetFilter={resetFilter} hasFilters={hasFilters}/>
            <Mutation mutation={DELETE_EMAIL_TEMPLATE}>
            {(deleteEmailTemplate, { error, loading, data }) => {
              if (loading) return <div>Loading...</div>
              if (error) return <div>There was an error.</div>
              if (data && data.deleteEmailTemplate && data.deleteEmailTemplate.success) {
                refetchTemplates()
              }
              
              return templates.map((template,index) => {
                return (
                  <TemplateCard 
                    key={`email-${template.id}`}
                    updateFunc={UPDATE_EMAIL_TEMPLATE}
                    to={'email_templates'}
                    index={index}
                    id={template.id}
                    moveCard={moveCard}
                    deleteFunc={deleteEmailTemplate} 
                    template={template} 
                    isReposition={isReposition}
                    maxWidth={state.maxWidth}
                    progress={progress[template.id]}
                    resetThemeImage={resetTemplateImage}
                    refetchTemplates={refetchTemplates}
                    usePixie={true}
                    onCardSelect={onCardSelect}
                    webflowEnabled={partner.platformFeatures.webflow?.enabled}
                  />
                )
              })
            }}
          </Mutation>
        </>
      }
      </Grid>
      {
        !!fetchMoreLoading &&
        <Grid container alignItems="center" justify="center" style={{ paddingTop: "10px" }}>
          Loading Data...
        </Grid>
      }
    </div>
    )
  }

  export default EmailTemplates
