import React, { useEffect } from 'react'
import UnlayerEditor from '../UnlayerEditor'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/EditOutlined'
import CopyIcon from '@material-ui/icons/FileCopyOutlined'
import Slide from '@material-ui/core/Slide'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CreatableSelect from 'react-select/creatable'
import FormHelperText from '@material-ui/core/FormHelperText';
import Snackbar from '@material-ui/core/Snackbar'
import axios from "axios"

import { UserContext } from '../../../UserContext'
import { getTagOptions } from '../../common/constants'
import useDebounce from "../../../utils/debounce"

import { useQuery } from '@apollo/react-hooks';
import runtimeEnv from '@mars/heroku-js-runtime-env'
import IS_EMAIL_TEMPLATE_SLUG_AVAILABLE from "../../../graphql/query/isEmailTemplateSlugAvailable"
import CodeMirrorEditor from '../../common/CodeMirrorEditor';
import { copyToClipboard } from '../../../utils';
import Switch from '@material-ui/core/Switch';

const env = runtimeEnv()

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  cardContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  flex: {
    flex: 1,
  },
  main: {
    width: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 100
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  reactSelect: {
    zIndex: 1
  }
})

function Transition(props) {
  return <Slide direction="up" {...props} />
}

function TemplateSlugInput ({ id, name, value, onChange, setErrors }) {
  let debouncedValue = useDebounce(value, 300)

  const { data, loading, error } = useQuery(IS_EMAIL_TEMPLATE_SLUG_AVAILABLE, {
    fetchPolicy: 'network-only',
    variables: { id, slug: debouncedValue }
  });
  const { isEmailTemplateSlugAvailable: { success = false } = {}} = data || {}

  const handleChange = (e) => {
    const val = e.target.value.toLowerCase().replace(/[^a-zA-Z-]/g, '')
    onChange({ target: { name, value: val }})  
  }

  useEffect(() => {
    setErrors({ slug: !success })
  },[success])

  return (
    <>
      <FormControl margin="normal" required fullWidth error={!success}>
        <InputLabel htmlFor="slug">Template Slug</InputLabel>
        <Input
          name={name}
          value={value}
          onChange={handleChange}
          autoFocus
        />
        { 
          !loading && !success &&
          <FormHelperText>{ !value ? 'Cannot be empty' : 'Slug already exists!' }</FormHelperText>
        }
      </FormControl>
    </>
  )
}

class Container extends React.Component {
  constructor(props) {
    super(props)
    
    const { template = {}, templatesCount } = this.props

    const json = Object.keys(template.json || {}).length > 0 ? template.json : null;
    this.state = {
      name: template.name || '',
      position: template.position || templatesCount + 1,
      premium: template.premium || false,
      webflowSettings: template.webflowSettings,
      live: template.live || false,
      json,
      unrollTemplateId: template.unrollTemplateId || null,
      tags: template.tags || [],
      designFormat: template.designFormat || 'visual_designer',
      showEditor: false,
      slug: template.slug || "",
      showCodeMirror: false,
      errors: [],
      showAlert: false,
      alertData: {},
      stringifyedJson: json,
      templateHtml: "",
      showOnAllSite: !(template.siteIds && template.siteIds.length > 0) ,
      siteIds: Array.isArray(template.siteIds) ? template.siteIds : []
    }
  }
  
  setErrors = (value) => {
    const { errors } = this.state
    this.setState({
      errors: { ...errors, ...value }
    })
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
    })
  }

  handleSiteIds = (newValue, actionMeta) => {
    const siteIds = newValue ? newValue.map(item => {
      return item.value
    }) : []
    this.setState({ siteIds })
  };

  handleChangeToggle = (e) => {
    this.setState({
      [e.target.name]:!this.state[e.target.name]
    })
  }

  handleWebflowChange = (key,value) => {
    const { webflowSettings } = this.state
    this.setState({
      webflowSettings: Object.assign({ ...webflowSettings, [key]: value })
    })
  }

  isValidInput = () => {
    const { errors } = this.state
    let isValid = true

    for(const error in errors) {
      if(errors[error]) {
        isValid = false
      }
    }

    return isValid
  }

  handleChange = (newValue, actionMeta) => {
    const tags = newValue ? newValue.map(item => {
      return item.value
    }) : []
    this.setState({ tags })
  };

  handleSave = (stringifyedJson) => {
    try {
      if (['null', '{}'].includes(stringifyedJson)) {
        throw 'JSON cannot be empty';
      }
      const parsedJson = JSON.parse(stringifyedJson);
      this.setState({ showCodeMirror: false, json: parsedJson });
    } catch (error) {
      this.setState({
        showAlert: true,
        alertData: {
          type: 'error', message: 'Invalid JSON'
        }
      });
    }
  }

  handleSendTestEmail = async () => {
    const baseApiUrl = env.REACT_APP_RAILS_URL || "http://localhost:3000"
    const response = await axios.post(`${baseApiUrl}/email_templates/partner_portal_test_email?id=${this.context.id}&email_template_name=${this.props.template.name}`, {
      data: {
        templateHtml: this.state.templateHtml
      }
    })
    const { data: { message = "", status = "error" } = {} } = response || {}
    this.setState({
      showAlert: true,
      alertData: {
        type: status === 'success' ? 'success' : 'error', message
      }
    })
  }

  handleSetTemplateHtml = (html) => {
    this.setState({
      templateHtml: html
    })
  }

  render() {
    const { classes, onSave, partner } = this.props
    const { name, position, premium, html, live, json, unrollTemplateId, tags, designFormat, showEditor, slug, webflowSettings, webflowPremium, showCodeMirror, alertData, showAlert, stringifyedJson, showOnAllSite, siteIds } = this.state
    let user = this.context
    const options = getTagOptions(user,'emails');
    const strJson = typeof(stringifyedJson) === 'string' ? stringifyedJson : JSON.stringify(stringifyedJson, null, 2);
   
    return (
      <div className={classes.main}>
        <form onSubmit={async(e) => {
          e.preventDefault()
          onSave({ name, position: parseInt(position), premium, live, json, html, tags, designFormat, slug, webflowSettings, webflowPremium, siteIds: showOnAllSite ? [] : siteIds })
        }}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="name">Name</InputLabel>
            <Input
              id="name"
              name="name"
              value={this.state.name}
              onChange={(e) => { this.handleInputChange(e) }}
              autoFocus
            />
          </FormControl>
          
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="position">Position</InputLabel>
            <Input
              id="position"
              name="position"
              value={this.state.position}
              onChange={(e) => { this.handleInputChange(e) }}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth classes={{ root: classes.reactSelect }}>
            <InputLabel className="MuiInputLabel-shrink" htmlFor="tags">Tags</InputLabel>
            <CreatableSelect
              isMulti
              onChange={this.handleChange}
              defaultValue={tags.map(item => ({ value: item, label: item }))}
              options={options}
              styles={{ 
                control: styles => ({ ...styles, 
                  margin: 0,
                  marginTop: 16,
                  border: 0,
                  borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                  borderRadius: 0,
                  minHeight: 32,
                  boxShadow: 'none',
                  ':hover': {
                    borderColor: 'rgba(0, 0, 0, 0.42)',
                  },
                }),
                container: styles => ({
                  fontFamily: 'sans-serif',
                  zIndex: 2,
                }),
                indicatorsContainer: styles => ({
                  display: 'none',
                }),
                placeholder: styles => ({
                  display: 'none',
                }),
              }}
            />
          </FormControl>
          <TemplateSlugInput
            id={this.props.template && this.props.template.id}
            value={slug} 
            name="slug"
            onChange={(e) => { this.handleInputChange(e) }}
            setErrors={this.setErrors}
          />
          <FormControl>
            <FormControlLabel
              value="start"
              control={<Switch color="primary" name="showOnAllSite" checked={showOnAllSite} defaultChecked={true} onChange={ (e) => { this.handleChangeToggle(e) } } />}
              label="Show on all sites"
              labelPlacement="start"
              style={{ marginLeft: '0' }}
            />
          </FormControl>
          {
            !showOnAllSite && 
            <FormControl style={{ marginTop: '0' }} required fullWidth>
              <InputLabel htmlFor="siteIds">Site Ids</InputLabel>
              <CreatableSelect
                isMulti
                onChange={this.handleSiteIds}
                defaultValue={siteIds.map(item => ({ value: item, label: item }))}
                formatCreateLabel={userInput => `Add siteId "${userInput}"`}
                styles={{ 
                  control: styles => ({ ...styles, 
                    margin: 0,
                    marginTop: 16,
                    border: 0,
                    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                    borderRadius: 0,
                    minHeight: 32,
                    boxShadow: 'none',
                    ':hover': {
                      borderColor: 'rgba(0, 0, 0, 0.42)',
                    },
                  }),
                  container: styles => ({
                    fontFamily: 'sans-serif'
                  }),
                  indicatorsContainer: styles => ({
                    display: 'none',
                  }),
                  placeholder: styles => ({
                    display: 'none',
                  }),
                }}
              />
            <FormHelperText>Enter Site Ids</FormHelperText>
            </FormControl>
          }
          <FormControl margin="normal" fullWidth>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.premium}
                    onChange={(e) => { this.handleInputChange(e) }}
                    name="premium"
                    value="premium"
                  />
                }
                label="Premium"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.live}
                    onChange={(e) => { this.handleInputChange(e) }}
                    name="live"
                    value="live"
                  />
                }
                label="Live"
              />
              {
                this.props.template?.id && partner.platformFeatures.webflow?.enabled &&
                <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.webflowSettings?.publish !== undefined ? this.state.webflowSettings?.publish : (this.state.webflowSettings?.id ? true : false)}
                      onChange={(e) => { this.handleWebflowChange('publish', e.target.checked) }}
                      
                    />
                  }
                  label="Webflow Gallery"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.webflowSettings?.premium ? true : false}
                      onChange={(e) => { this.handleWebflowChange('premium', e.target.checked) }}
                    />
                  }
                  label="Webflow Premium"
                />
                </>
              }
            </FormGroup>
            </FormControl>

          {designFormat === "visual_designer" && <FormGroup row>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => { this.handleEditorOpen() }}
            >
              Edit Template
            </Button>
          </FormGroup>}
        
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Button
              style={{ marginRight: '20px' }}
              onClick={() => {
                copyToClipboard(strJson).then(() => {
                  this.setState({
                    showAlert: true,
                    alertData: {
                      type: 'success',
                      message: 'JSON Copied to Clipboard !'
                    }
                  });
                }).catch(() => {
                  this.setState({
                    showAlert: true,
                    alertData: {
                      type: 'error',
                      message: 'Error in copying to clipboard !'
                    }
                  });
                });
              }}
              startIcon={<CopyIcon />}>
                Copy JSON
            </Button>
            <Button
              onClick={() => this.setState({ showCodeMirror: true })}
              startIcon={<EditIcon />}>
                Edit JSON
            </Button>
          </div>

          <hr />
          
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!this.isValidInput()}
          >
            Save
          </Button>
        </form>
        
        <Dialog
          fullScreen
          open={this.state.showEditor}
          onClose={this.handleEditorClose}
          TransitionComponent={Transition}
        >
          <AppBar color="secondary" className={classes.appBar}>
            <Toolbar>
              <IconButton color="inherit" onClick={this.handleEditorClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                Editor
              </Typography>
              {
                this.state.templateHtml && 
                <Button color="inherit" onClick={this.handleSendTestEmail}>
                  Send Test Email
                </Button>
              }
              <Button color="inherit" onClick={this.handleEditorSave}>
                save
              </Button>
            </Toolbar>
          </AppBar>
          
          {showEditor && <UnlayerEditor
            displayMode="email"
            json={json}
            templateId={unrollTemplateId}
            closeEditor={this.handleEditorClose}
            handleSetTemplateHtml={this.handleSetTemplateHtml}
          />}
        </Dialog>

        <CodeMirrorEditor
          onEditorChanged={(editor, data, value) => this.setState({ stringifyedJson: value })}
          onEditorCancel={() => this.setState({ showCodeMirror: false, stringifyedJson: json })}
          onSave={() => this.handleSave(strJson)}
          stringifyedJson={strJson}
          isOpen={showCodeMirror} 
          transition={Transition}
          classes={classes}
        />
        
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showAlert}
          autoHideDuration={5000}
          onClose={() => this.setState({ showAlert: false })}
        >
          <Alert severity={alertData.type}>
            {alertData.message}
          </Alert>
        </Snackbar>
      </div>
    )
  }
  
  handleEditorSave = () => {
    try {
      window.unlayer.exportHtml(data => {
        const { design, html } = data
        this.setState({ json: design, stringifyedJson: design, html })
        this.handleEditorClose()
      })
    } catch(err) {
      console.log("ERR", err)
      this.handleEditorClose()
    }
  }

  handleEditorClose = () => {
    this.setState({
      showEditor: false,
    })
  }

  handleEditorOpen = (page) => {
    this.setState({
      showEditor: true,
    })
  }
}


Container.contextType = UserContext
export default withStyles(styles)(Container)
