import React, { useState, useCallback, useEffect } from 'react'
import TemplateCard from '../../common/DraggableCard'
import _ from 'lodash'
import update from 'immutability-helper'
import { Grid, CircularProgress } from '@material-ui/core'
import { useQuery, useMutation } from '@apollo/react-hooks'
import FilterBar from '../../common/filterBar'
import RepositionBar from '../../common/repositionBar'
import { actions, filterBy, sortBy } from './FilterConfig'
import { DELETE_JOURNEY_TEMPLATE, UPDATE_JOURNEY_TEMPLATE } from '../../../graphql/mutation/journey'
import { FETCH_JOURNEY_TEMPLATES } from '../../../graphql/query/journey'
import PixieImageEditor from '../../common/pixie'
import { savePixieFileAndUpload } from '../../../utils/pixie';

const JourneyTemplates = (props) => {
  const { partner } = props
  const defaultProps = {
    isReposition: false,
    maxWidth: 350,
    templates: [],
    count: 0,
    sortBy: 'POSITION',
    filterBy: {
    }
  }
  const [progress, setProgress] = useState({})
  const [state,setState] = useState({
   ...defaultProps
  })

  const { data, refetch } = useQuery(FETCH_JOURNEY_TEMPLATES, {
    fetchPolicy: 'network-only',
    variables: {
      ...state,
    }
  })


  const [loading, setLoading] = useState(false)
  const { isReposition, templates } = state
  const [updateJourneyTemplate] = useMutation(UPDATE_JOURNEY_TEMPLATE)
  const [deleteJourneyTemplate] = useMutation(DELETE_JOURNEY_TEMPLATE, { refetchQueries: ['JourneyTemplates'] })
  
  useEffect(() => {
    setState({ ...state, templates: data?.journeyTemplates || [] })
  },[data])


  const hasFilters = () => {
    const { filterBy, sortBy } = state
    const { ...stateFilterBy } = filterBy
    const { ...defaultFilterBy } = defaultProps.filterBy
    const isEqual = _.isEqual(stateFilterBy,defaultFilterBy)

    if(!isEqual || defaultProps.sortBy !== sortBy )
    {
      return true
    }
    else
      return false
  }

  const callback = (index, { name, value }, reset = false, reposition = false) => {
    const { filterBy, count } = state
    if(reset) {
      setState({ ...state, ...defaultProps, count: count+1 })
    }
    else {
      if(index === 'filterBy') {
        if ( value !== '' )
          setState({ ...state, filterBy: { ...filterBy, [name]: value }})
        else
        {
          let newFilterBy = { ...filterBy }
          delete newFilterBy[name]
          setState({ ...state, filterBy: newFilterBy })
        }
      }
      if(index === 'sortBy') {
        setState({ ...state, sortBy: value })
      }
    }
  }
  
  const resetFilter = (reposition = false) => {
    callback(null, {}, true, reposition)
  }

  const updateState = (values, doUpdate = false, doRefetch = false) => {
    if(values.isReposition !== undefined)
    values.isReposition === true ? resetFilter(values.isReposition) : resetFilter()
    setState({ ...state, ...values, ...(doRefetch && { templates: props.templates })}) 
    if(doUpdate) {
      setLoading(true)
      Promise.all(
        templates.map(async ({id, position},index) => {
          await updateJourneyTemplate({ variables: {
            input: {
              id: parseInt(id),
              position: index
            }
          }})
      })).then(() => {
        refetch().then(() => setLoading(false))
      })
    }
  }

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = templates[dragIndex]
      setState({
        ...state,
        templates:
        update(templates, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      })
    },
    [state]
  )

  const resetTemplateImage = (id) => {
    updateJourneyTemplate({
      variables: {
        input: {
          id,
          screenshotUrl: null
        }
      }
    })
  }

  const onSave = (file, name) => {
    const templateId = window.templateId
    const config = {
      onUploadProgress: progressEvent => {
        setProgress({ [templateId]: ((progressEvent.loaded/progressEvent.total) * 100) })
      }
    }
    
    savePixieFileAndUpload(file, name, partner, config)
      .then(response=>{
        const {url, key} = response;
        updateJourneyTemplate({
          variables: {
            input: {
              id: templateId,
              screenshotUrl: `${url}/${key}`
            }
          }
        }).then((resp) => {
          setProgress({ [templateId]: null })
        })
      }).catch((err)=> {
        console.error(err)
      });
    
      
    window.pixie.close();
    delete window.pixie;
  }

  return (
    <>
      <PixieImageEditor
        key="journey-templates-pixie"
        id="journey-templates"
        onSave={(file, name)=> onSave(file, name)}
      />
      <FilterBar 
        config={{ filterBy, actions, sortBy }} 
        callback={callback} 
        disabled={isReposition}
        sorts={state?.sortBy} filters={state?.filterBy}
        tagKey='journeys'
      />
      <Grid container direction="row" spacing={2} style={{ padding: 8*3 }}>
        {
          loading ? <CircularProgress color="secondary" /> :
          <>
            <RepositionBar {...state} callback={updateState} resetFilter={resetFilter} hasFilters={hasFilters()}/>
            <>
            {
              (templates || []).map((template,index) => {
                return (
                  <TemplateCard 
                    key={template.id}
                    updateFunc={UPDATE_JOURNEY_TEMPLATE}
                    to={'journey_templates'}
                    index={index}
                    id={template.id}
                    moveCard={moveCard}
                    deleteFunc={({ variables: { id }}) => deleteJourneyTemplate({ variables: { input: { id }}}) } 
                    template={template} 
                    isReposition={isReposition}
                    maxWidth={state.maxWidth}
                    resetThemeImage={resetTemplateImage}
                    refetchTemplates={refetch}
                    usePixie={true}
                    toggleInputs = {false}
                  />
                )
              })
            }
            </>
        </>
      }
      </Grid>
    </>
  )
}

export default JourneyTemplates
