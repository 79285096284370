const JourneyTemplate = `
  id
  name
  position
  description
  live
  screenshotUrl
  tags
  siteIds
`

export { JourneyTemplate }