import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import IconButton from '@material-ui/core/IconButton';
// import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const useStyles = makeStyles(theme => ({
  domainRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const Domains = ({ label, name, value, handleChange, error, handleBlur, /*icon = false, handleIconClick = () => ''*/  }) => {
  const classes = useStyles();
  
  return (
    <TextField
      label={label}
      name={name}
      value={value}
      error={error}
      helperText={error &&"Incorrect entry."}
      onBlur={handleBlur}
      onChange={handleChange}
      style={{ width: 350 }}
      placeholder={label}
      margin="normal"
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      // InputProps={{
      //   endAdornment: (
      //   <InputAdornment position="end">
      //     <IconButton
      //       aria-label={`${icon} icon`}
      //       onClick={handleIconClick}
      //     >
      //       { icon === 'Delete' && <DeleteOutlineOutlinedIcon/> }
      //     </IconButton>
      //   </InputAdornment>
      //   ),
      // }}
    />
  );
};

export default Domains;
